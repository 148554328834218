export const theme = {
  color: {
    black: `#000000`,
    white: `#ffffff`,
    transparent: `transparent`
  },
  transition: {
    standard: `0.2s ease`
  },
  background: {
    cover: `center center / cover no-repeat`
  }
};
