/**
 * Head component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function Head({
  description,
  lang,
  defaultMeta,
  defaultLink,
  title = 'default'
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            assetsPath
          }
        }
      }
    `
  );

  const siteMetadata = site.siteMetadata;
  const metaDescription = description || siteMetadata.description;
  const defaultTitle = siteMetadata.title;

  const meta = [
    {
      name: `description`,
      content: metaDescription
    },
    {
      property: `og:title`,
      content: title
    },
    {
      property: `og:description`,
      content: metaDescription
    },
    {
      property: `og:type`,
      content: `website`
    },
    {
      name: `twitter:card`,
      content: `summary`
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata?.author || ``
    },
    {
      name: `twitter:title`,
      content: title
    },
    {
      name: `twitter:description`,
      content: metaDescription
    }
  ].concat(defaultMeta);

  const link = [
    {
      rel: `icon`,
      href: `/favicon.ico`,
      type: `image/x-icon`
    },
    {
      rel: `apple-touch-icon`,
      href: `${siteMetadata.assetsPath}images/ui/apple-touch-icon.png`
    }
  ].concat(defaultLink);

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={
        title === 'default' || title === null || title === undefined
          ? defaultTitle
          : `%s | ${defaultTitle}`
      }
      meta={meta}
      link={link}
    />
  );
}

Head.defaultProps = {
  lang: `en`,
  defaultMeta: [],
  defaultLink: [],
  description: ``
};

export default Head;
