/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, {
  ThemeProvider,
  createGlobalStyle
} from 'styled-components/macro';

import Head from './Head';
// import Header from './Header';
// import Social from './Social';

// import { assetsPath } from '../global';
import { theme } from '../theme';
import { fontStyles, globalStyles } from '../css';

export const TEMPLATE = {
  DEFAULT: 'default',
  GALLERY: 'gallery',
  DETAIL: 'detail',
  TEXT: 'text'
};

const Layout = ({ children, template = TEMPLATE.DEFAULT, title }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          assetsPath
        }
      }
    }
  `);

  const siteMetadata = data.site.siteMetadata;

  let TemplateContainer;

  switch (template) {
    case TEMPLATE.GALLERY:
      TemplateContainer = GalleryContainer;
      break;
    case TEMPLATE.DETAIL:
      TemplateContainer = DetailContainer;
      break;
    case TEMPLATE.TEXT:
      TemplateContainer = TextContainer;
      break;
    default:
      TemplateContainer = DefaultContainer;
  }

  return (
    <ThemeProvider theme={theme}>
      <Head title={title} />
      <GlobalStyle assetsPath={siteMetadata.assetsPath} />
      <ContentContainer>
        {/*
          <Header siteTitle={siteMetadata.title} />
        */}
        <main>
          <TemplateContainer>{children}</TemplateContainer>
        </main>
        {/*
          <footer><Social />Copyright © {new Date().getFullYear()} Aaron Sarnat. All rights reserved.</footer>
        */}
      </ContentContainer>
    </ThemeProvider>
  );
};

export default Layout;

const GlobalStyle = createGlobalStyle`
  ${fontStyles}
  ${globalStyles}
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const GalleryContainer = styled.div``;

const DetailContainer = styled.div``;

const TextContainer = styled.div``;

const DefaultContainer = styled.div``;
