import { css } from 'styled-components';

export const globalStyles = css`
  html,
  body,
  #___gatsby,
  #gatsby-focus-wrapper,
  .tl-edges,
  .tl-wrapper {
    width: 100%;
    height: 100%;
  }

  html {
    background: ${({ theme }) => theme.color.white};
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    // overflow-y: scroll;
  }

  body {
    margin: 0;
    background: ${({ theme, assetsPath }) =>
      `${theme.color.white} url(${assetsPath}scratch/scratch-wallpaper.jpg) ${theme.background.cover}`};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Raleway, sans-serif;

    color: ${({ theme }) => theme.color.white};
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  main,
  footer {
    padding: 100px;
  }

  footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    padding: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
